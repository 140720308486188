<template>
	<div class="booking-box pd16_15">
		<div class="flex alcenter space">
		
			<div class="flex alcenter">
				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img  v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/mall_home_yushou@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'预售推荐'}}</span>
			</div>
			<div>
				<span class="ft12 cl-main">查看全部</span>
				<span class="iconfont iconarrowright_black ft12 cl-main ml5"></span>
			</div>
		</div>
		<div class="mt16">
			<a-spin :spinning="false">
				<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
				<div v-else class="flex">
					<div class="product-img-box small">
						<img class="product-img" style="object-fit: cover" :src="calldata.cover_img" />
						<div class="product-tag flex alcenter">
							<span class="ft12 cl-w">付款{{calldata.yu_days}}天后发货</span>
						</div>
					</div>
					<div class="product-r small pd10">
						<div class="ft14 cl-main ftw600  text-over2" style="line-height: 20px;">
							{{calldata.name}}
						</div>
						<div class="mt12">
							<span class="ft12 cl-price">预售价¥</span>
							<span class="ft16 ftw600 cl-price">{{calldata.yu_price}}</span>
							<span class="ft12 cl-notice text-line ml5">¥{{calldata.original_price}}</span>
						</div>
						<div class="mt12 flex alcenter space">
							<span class="ft12 cl-notice">已售 {{calldata.fictitious_take_count}}</span>
							<span class="btn-buy" :style="{background:mainColor}">购买</span>
						</div>
					</div>	
				</div>	
			</a-spin>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			calldata(){
				return this.datas.datas[0];
			}
		},
		data(){
			return{
				loading:false,
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.booking-box{
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
		position: relative;
		overflow: hidden;
	}
</style>