<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="900px"
		      title="设置组件"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="mt12 flex alcenter">
			   <div style="width: 100px;">距离上组件</div> <a-input-number v-model="mydatas.style.mt" :min="0" :max="50" />px
			</div>
		
			<div class="mt12 flex alcenter">
			   <div style="width: 100px;">模块标题</div> <a-input v-model="mydatas.style.title" style="width: 200px;"></a-input>
			</div>
			<div class="mt12 flex alcenter">
			   <div style="width: 100px;">模块图标</div> 
			   <div>
				   <upload-img v-model="mydatas.style.icon" typeClass="small"></upload-img>
			   </div>
			</div>
			<div class="mt12">
				<table style="width: 100%;" class="wxb-table">
					<thead>
						<tr>
							<th>缩略图</th>
							<th>次卡名称</th>
							<th>秒杀价</th>
							<th>省金额</th>
							<th>操作</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in  mydatas.datas" :key="index">
							<td>
								<upload-img v-model="mydatas.datas[index].cover_img"  typeClass="small"></upload-img>
							</td>
							<td>
								<a-input v-model="mydatas.datas[index].name"></a-input>
							</td>
							<td>
								<a-input v-model="mydatas.datas[index].flash_price"></a-input>
							</td>
							<td>
								<a-input v-model="mydatas.datas[index].save_money"></a-input>
							</td>
							<td>
								<a-button @click="delAct(index)" type="link">删除</a-button>
							</td>
						</tr>
						<tr>
							<td colspan="5">
								<a-button @click="addAct" type="link">+新增一条</a-button>
							</td>
						</tr>
						
					</tbody>
				</table>
			</div>
			
			
			
		</a-modal>
	</div>
</template>

<script>
	import uploadImg from '../../../../../components/upload/upload.vue';
	export default{
		components:{
			uploadImg
		},
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			},
			addAct(){
				this.mydatas.datas.push({cover_img:'',name:'',flash_price:100,price:100,save_money:1000,flash_sale_djs:86400,is_flash_sale_expire:0,is_flash_sale:1,fictitious_take_count:10});
			},
			delAct(index){
				this.mydatas.datas.splice(index,1);
			},
		}
	}
</script>

<style>
	
</style>