<template>
	<div class="coupon-box pd16_15">
		<div class="flex alcenter space">
			<div class="flex alcenter">

				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img  v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/home_tui_service@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'优惠抢购'}}</span>
				
			</div>
			<div>
				<span class="ft12 cl-main">查看全部</span>
				<span class="iconfont iconarrowright_black ft12 cl-main ml5"></span>
			</div>
		</div>
		<a-spin :spinning="false">
			<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
			<div v-else class="pt15">
				<div v-for="(item,index) in calldata" :key="index" :class="{mt16:index > 0}" class="flex">
					<div class="service-item-l">
						<img class="service-img" :src="item.cover_img"/> 
						<img v-if="item.is_flash_sale == 1 && item.is_flash_sale_expire == 0" class="miaosha-tag" src="../../../../../assets/image/label_miaosha@3x.png"/>
						<div  v-if="item.is_flash_sale == 1 && item.is_flash_sale_expire == 0" class="djs-tag">
							距结束<time-countdown :t="item.flash_sale_djs" type="text" size="ft12"></time-countdown>
						</div>
					</div>
					<div class="service-item-r pl15">
						<div class="ft14 cl-main ftw600 text-over">{{item.abbreviation}}</div>
						<div class="mt12">
							<span class="ft12 cl-price">{{item.is_flash_sale == 1 && item.is_flash_sale_expire == 0 ? '秒杀价' : '会员价'}}</span>
							<span class="ft12 cl-price">¥</span>
							<span class="ft16 ftw600 cl-price">{{item.is_flash_sale == 1 && item.is_flash_sale_expire == 0 ? item.flash_price : item.vip_price}}</span>
							<span class="ft12 cl-notice text-line ml10">¥{{item.market_price}}</span>
						</div>
						<div class="mt12 flex alcenter space">
							<span class="ft12 cl-notice">已售 {{item.fictitious_take_count}}</span>
							<div class="btn-mini" style="color: #FFFFFF" :style="{background:mainColor}">抢购</div>
						</div>
					</div>
				</div>	
			</div>
		</a-spin>
	</div>
</template>

<script>
	import timeCountdown from '../../djs.vue';
	export default{
		components:{
			timeCountdown
		},
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
			}
		},
		computed:{
			calldata(){
				return this.datas.datas;
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.tuan-box{
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
	}
	
	
</style>