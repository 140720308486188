<template>
	<div class="store-box pd16_15 flex">
		<div>
			<div class="flex alcenter">
				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/mall_home_tunhuo@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'我要囤货'}}</span>
			</div>
			<div class="mt16 ft12 cl-notice text-center">
				放店里不占空间
			</div>
			<div class="mt30 flex center">
				<span class="btn-buy" :style="{background:mainColor}">去逛逛</span>
			</div>
		</div>
		<div style="margin-left: 6px;">
			<img style="width: 6px; height: 114px;" src="../../../../../assets/image/mall_vlleft@3x.png" />
		</div>
		<div style="width: 190px;" class="ml16">
			<a-spin :spinning="false">
				<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
				<div v-else class="flex space">
					<div v-for="(item,index) in calldata" :key="index" class="store-goods-box">
						<div class="product-img-box">
							<div class="product-tag">送¥{{item.gift_total_price}}礼品</div>
							<img :src="item.cover_img" class="product-img" />
						</div>	
						<div class="text-over text-center mt8 ft12 cl-main ftw600">{{item.name}}</div>
					</div>
					
				</div>	
			</a-spin>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
				
			}
		},
		computed:{
			calldata(){
				return this.datas.datas;
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.store-box{
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
		position: relative;
		overflow: hidden;
	}
	.store-goods-box{
		width: 90px;
	}
	.store-goods-box .product-img-box{
		width: 90px;
		height: 90px;
	}
	.store-goods-box .product-img-box .product-img{
		width: 90px;
		height: 90px;
	}
</style>